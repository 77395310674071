<template>
  <div class="schools">
    <div class="container">
      <div class="schools__box">
        <img
          :src="require('@/assets/images/zaman.png')"
          alt="zaman"
          class="zaman"
        />
        <h2 class="schools__title">{{ $t("schools.school1.name") }}</h2>
        <div class="schools__info">
          <p v-html="$t('schools.school1.text')"></p>
          <div
            class="schools__flex"
            v-if="$t('schools.school1.urlName').length > 0"
          >
            <a :href="$t('schools.school1.urlLink')" target="_blank">{{
              $t("schools.school1.urlName")
            }}</a>
            <a :href="$t('schools.school1.insta')" target="_blank"
              ><div class="insta-icon"></div
            ></a>
          </div>
        </div>
      </div>
      <div class="schools__box1">
        <img
          :src="require('@/assets/images/zerde.png')"
          alt="zerde"
          class="zerde"
        />
        <h2 class="schools__title1">{{ $t("schools.school2.name") }}</h2>
        <div class="schools__info1">
          <p v-html="$t('schools.school2.text')"></p>
          <div
            class="schools__flex"
            v-if="$t('schools.school2.urlName').length > 0"
          >
            <a :href="$t('schools.school2.urlLink')" target="_blank">{{
              $t("schools.school2.urlName")
            }}</a>
            <a :href="$t('schools.school2.insta')" target="_blank"
              ><div class="insta-icon"></div
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.insta-icon {
  width: 22px;
  height: 22px;
  background-image: url("../assets/icons/insta-orange.svg");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 20px;
}
.zaman {
  width: 666px;
  height: 612px;
}
.zerde {
  width: 745px;
  height: 677px;
}
.schools {
  background-color: #ffffff;
  padding: 60px 0;
  &__flex {
    display: flex;
    align-items: center;
    margin-top: 20px;
    a {
      color: #e66e00;
    }
  }
  &__title {
    font-size: 35px;
    line-height: 41px;
    color: #202020;
    width: 631px;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
    padding-bottom: 14px;
    border-bottom: 2px solid #202020;
  }
  &__title1 {
    font-size: 35px;
    line-height: 41px;
    color: #202020;
    width: 631px;
    text-align: left;
    position: absolute;
    top: 50px;
    left: 0;
    padding-bottom: 14px;
    border-bottom: 2px solid #202020;
  }
  &__box {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
  }
  &__box1 {
    width: 100%;
    text-align: right;
    position: relative;
  }
  &__info {
    position: absolute;
    padding: 40px;
    font-size: 21px;
    right: 0;
    top: 100px;
    max-width: 645px;
    line-height: 25px;
    color: #ffffff;
    background: #00303a;
    border-radius: 5px;
    &::after {
      content: "";
      width: 20px;
      display: block;
      position: absolute;
      width: 389px;
      height: 30px;
      background: #e66e00;
      border-radius: 2px;
      bottom: -10px;
      right: 0;
    }
  }
  &__info1 {
    position: absolute;
    padding: 40px;
    font-size: 21px;
    left: 0;
    top: 180px;
    max-width: 602px;
    text-align: left;
    line-height: 25px;
    color: #ffffff;
    background: #00303a;
    border-radius: 5px;
    &::before {
      content: "";
      width: 20px;
      display: block;
      position: absolute;
      width: 389px;
      height: 30px;
      background: #e66e00;
      border-radius: 2px;
      top: -10px;
      left: 0;
    }
  }
}
@media (max-width: 768px) {
  .schools {
    padding-top: 95px;
    &__box {
      margin-bottom: 20px;
    }
    &__info1 {
      position: relative;
      top: -10px;
      font-size: 12px;
      padding: 20px;
      line-height: 16px;
      &::before {
        left: -10px;
        width: 268px;
        top: -10px;
        height: 15px;
      }
    }
    &__info {
      position: relative;
      top: -10px;
      font-size: 12px;
      padding: 20px;
      line-height: 16px;
      &::after {
        width: 268px;
        top: -10px;
        height: 15px;
        right: 0;
      }
    }
    &__title {
      font-size: 18px;
      line-height: 18px;
      top: 20px;
      width: 151px;
    }
    &__title1 {
      font-size: 20px;
      line-height: 18px;
      width: 200px;
    }
  }
  .zaman,
  .zerde {
    width: 80%;
    height: auto;
  }
}
</style>
